import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faFileContract ,faXmark,faIdBadge,faCubesStacked , faRocket,faDiagramProject,faChartSimple } from '@fortawesome/free-solid-svg-icons'
import '../LandingPage.css';

import logo from '../img/logo.jpg';
import menu from '../img/menu.png';

import logoTransparent from '../img/logo-removebg-preview.png';



import telgrame from '../img/eabe4ef5dd7222a6d877060368ee6faa.svg';

import img4 from '../img/about.png';


import five1 from '../img/five1.jpeg';
import five2 from '../img/five2.jpg';
import five3 from '../img/five3.jpg';
import five4 from '../img/five4-removebg-preview.png';
import five5 from '../img/five5-removebg-preview.png';


import six1 from '../img/c2d2e1c46eb5ef5806ee8f93db2cd155.png';
import six2 from '../img/six2.jpg';
import six3 from '../img/six4.jpg';
import six4 from '../img/d97f96415d306c0ef7ff1d41c3d435eb.png';



import imgDakhelaniyaOne from '../img/1-removebg-preview.png'
import imgDakhelaniyaTwo from '../img/7-removebg-preview.png'
import imgDakhelaniyaThree from '../img/2-removebg-preview.png'
import imgDakhelaniyaFour from '../img/4-removebg-preview.png'
import imgDakhelaniyaFive from '../img/3-removebg-preview.png'

import imgSiyad from '../img/12345.png'
function LandingPage () {

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
    console.log(menuVisible); 
  };


  return (
    <>
      <div className="landing-page">
        <header>
          <div className='container'>
            <div className='logo'>
              <a href="#home" rel="noreferrer">
                <img src={logo} alt=''/>  
              </a>
            </div>
            <ul>
              <li><a href={"#page-0"}>Home</a></li>
              <li><a href={"#page-1"}>Dev</a></li>
              <li><a href={"#page-2"}>Partner</a></li>
              <li><a href={"#page-3"}>Team</a></li>
              <li><a href={"#page-4"}>advisory</a></li>
              <li><a href={"#page-5"}>mkt</a></li>
              <li><a href={"#page-6"}>contact</a></li>
            </ul>
            <div className='icon'>
              <button onClick={toggleMenu}>
              {
                menuVisible
                ? <FontAwesomeIcon className='menu-icon menu' icon={faXmark} size='2xl' />
                : <FontAwesomeIcon className='menu-icon menu' icon={faBars} size='2xl' />
              }
              </button>
            </div>
            {/* <button onClick={toggleMenu}><img src={menu} alt='' className='menu'/></button> */}

            <ul className={`menu-resp ${menuVisible ? 'visible' : ''}`} style={{display:'none'}}>
              <li><a onClick={toggleMenu} href={"#page-0"}>Home</a></li>
              <li><a onClick={toggleMenu} href={"#page-1"}>Dev</a></li>
              <li><a onClick={toggleMenu} href={"#page-2"}>Partner</a></li>
              <li><a onClick={toggleMenu} href={"#page-3"}>Team</a></li>
              <li><a onClick={toggleMenu} href={"#page-4"}>advisory</a></li>
              <li><a onClick={toggleMenu} href={"#page-5"}>mkt</a></li>
              <li><a onClick={toggleMenu} href={"#page-6"}>contact</a></li>
            </ul>
          </div>
        </header>
        <section className='one' id='page-0'>
          <div className='container'>
            <div className='o-left'>
              <h1>Who Are We ?</h1>
              <p>Multipurpose team, multiplied results. Team is able to engage a full project - from ideation to incubation and go-to-market.</p>
              <a href="https://t.me/ObiWanKenobiChannel" target="_blank" rel="noreferrer">
                <button className="button button2">learn more</button>
              </a>
            </div>
            <div className='o-right'>

            </div>
          </div>
        </section>
        <section className='two' id='page-1'>
          <div className='container'>
            <h1>From Smart Contract to Blockchain</h1>
            <p>We have a devhouse specialized in crypto - so, whatever you project needs regarding development we can deliver: fast, tested and customized.</p>
            <div className="grid-container">
              <div className="grid-item">
                <FontAwesomeIcon className='icon icon1' size="2xl"  icon={faFileContract} />
                Smart Contract
              </div>
              <div className="grid-item">
                <FontAwesomeIcon className='icon icon2' size="2xl" icon={faIdBadge} />
                NFTs Smart Contract
              </div>
              <div className="grid-item">
                <FontAwesomeIcon className='icon icon3' size="2xl" icon={faCubesStacked} style={{"--fa-primary-color": "#000000", "--fa-secondary-color": "#000000",}} />
                App Stake & Farm
              </div>  
              <div className="grid-item">
              <FontAwesomeIcon className='icon icon4' size="2xl" icon={faRocket} />
                DApp DAO
              </div>
              <div className="grid-item">
              <FontAwesomeIcon className='icon icon5' size="2xl" icon={faDiagramProject} />
                Blockchain and Special Projects
              </div>
              <div className="grid-item">
              <FontAwesomeIcon className='icon icon6' size="2xl" icon={faChartSimple} />
                Marketplace & mktplace P2P
              </div>   
            </div>
          </div>
        </section>
        <section className='four' id='page-5'>
          <div className='container'>
            <h1>Our Marketing Strategy</h1>
            <h2>We invision next levels using IA, strategy, techniques,relationship and brains</h2>
            <img src={img4} alt=''/>
          </div>
        </section>
        <section className='three'>
          <div className='container'>
            <div className="grid-container">
                <div className="grid-item">
                  <h1>01</h1>
                  {/* <h3>Develop​ment</h3> */}
                  <p>
                    Website<br/>
                    Google Ads<br/>
                    Presentation<br/>
                    Videos<br/>
                  </p>
                </div>
                <div className="grid-item">
                  <h1>02</h1>
                  {/* <h3>Design projects</h3> */}
                  <p>
                    CMC<br/>
                    CoinGecko<br/>
                    Listings<br/>
                    Dextools<br/>
                  </p>
                </div>
                <div className="grid-item">
                  <h1>03</h1>
                  {/* <h3>New materials</h3> */}
                  <p>
                    Callers<br/>
                    YouTube<br/>
                    TikTok<br/>
                    Twitter<br/>
                  </p>
                </div>   
            </div>
          </div>
        </section>
        <section className='five' id='page-4'>
          <div className='container'>
            <h1>If you have the will, we have the way.</h1>
            <h2> From structuring the project to launch - we can provide all the tools and help your project to succeed through our Advisory Board.</h2>
            <div className="grid-container">
              <div className="grid-item">
                <img src={five1} alt=''/>
                <h1>BUSINESS STRATEGY</h1>
                <p>Business plan, accountability, tokenomics</p>
                <a href="https://t.me/ObiWanKenobiChannel" target="_blank" rel="noreferrer">
                  <button className="button button2">more</button>
                </a>
              </div>
              <div className="grid-item">
                <img src={five2} alt=''/>
                <h1>INCUBATION & INVESTMENT</h1>
                <p>Access to investors and full service (seed to launch)</p>
                <a href="https://t.me/ObiWanKenobiChannel" target="_blank" rel="noreferrer">
                  <button className="button button2">more</button>
                </a>
              </div>
              <div className="grid-item">
                <img src={five3} alt=''/>
                <h1>DOCUMENTS & INFO</h1>
                <p>Whitepaper, Pitch Deck, videos and else</p>
                <a href="https://t.me/ObiWanKenobiChannel" target="_blank" rel="noreferrer">
                  <button className="button button2">more</button>
                </a>
              </div>  
              <div className="grid-item">
                <img src={five4} alt=''/>
                <h1>MARKETING & COMMUNITY</h1>
                <p>Marketing plan, community, management,..</p>
                <a href="https://t.me/ObiWanKenobiChannel" target="_blank" rel="noreferrer">
                  <button className="button button2">more</button>
                </a>              
              </div>
              <div className="grid-item">
                <img src={five5} alt=''/>
                <h1>SUPPORT FOR THE PROCESS</h1>
                <p>We will support your project until the end</p>
                <a href="https://t.me/ObiWanKenobiChannel" target="_blank" rel="noreferrer">
                  <button className="button button2">more</button>
                </a>              
              </div>  
            </div>
          </div>
        </section>
        <section className='six' id='page-3'>
          <div className='container'>
            <h1>We present you the brains</h1>
            <h2>We have been in the space longe enough to assure you: brains are game changers,
                Our time together already brought to life more than 10 projects, raised more than USD 2MM in private and pre-sale.</h2>
            <div className="grid-container">
              <div className="grid-item">
                <img src={six1} alt=''/>
                <h1>Obi-Wan Jedi Dev</h1>
                <p>One of the most out of the box Devs in the space</p>
              </div>
              <div className="grid-item">
                <img src={six2} alt=''/>
                <h1>Geraldo Jr <br/>Strategy & Investments</h1>
                <p>Majored in Finance & Control, working in the space since 2018.</p>
              </div>
              <div className="grid-item">
                <img src={six3} alt=''/>
                <h1>Alessandra  Botelho <br/>Marketing</h1>
                <p>Majored in Marketing, been in crypto since 2020</p>
              </div>  
              <div className="grid-item">
                <img src={six4} alt=''/>
                <h1>Evan Garxes <br/>Community & Promo</h1>
                <p>Expert in Communities and promotion, in crypto since 2018.</p>
              </div>
            </div>
          </div>
        </section>
        <section className='siven' id='page-2'>
          <div className='container'>
            <div className='s-one'>
              <img src={imgSiyad} alt=''/>
            </div>
            <div className='s-two'>
              <h1>Partnership with the best</h1>
              <p>We can connect you and your project with the best. and get the best prices and conditions. No road is far enough for us.</p>
              <div className='elments'>
                  <div className="image-container">
                    <img src={imgDakhelaniyaOne} alt='image1' className='overlay-image'/>
                  </div>
                  <div className="image-container">
                    <img src={imgDakhelaniyaTwo} alt='image2' className='overlay-image'/>
                  </div>
                  <div className="image-container lll">
                    <img src={imgDakhelaniyaThree} alt='image3' className='overlay-image'/>
                  </div>
                  <div className="image-container im-4">
                    <img src={imgDakhelaniyaFour} alt='image3' className='overlay-image'/>
                  </div>
                  <div className="image-container im-5">
                    <img src={imgDakhelaniyaFive} alt='image3' className='overlay-image'/>
                  </div>
              </div>
            </div>
          </div>
        </section> 
        <footer id='page-6'>
          <div className='container'>
            <div className="grid-container">
              <div className="grid-item">
                <p>Copyright© 2023, All rights reserved for OBI-WAN Team</p>
              </div>
              <div className="grid-item">
                <img src={logoTransparent} alt=''/>
              </div>
              <div className="grid-item">
                <a href="https://t.me/ObiWanKenobiChannel" target="_blank" rel="noreferrer">
                  <img src={telgrame} alt=''/>
                </a>
              </div>    
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;
