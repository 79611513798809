import './App.css';
import LandingPage from './container/LandingPage';


function App() {

  return (
      <div className="App">
        <LandingPage/>
      </div>
  );
}

export default App;
